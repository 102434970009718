import axios from "axios";
import { types } from "../types/types";
import qs from "qs";
import { login, setIsAuth, setLoadingSession } from "./auth";
import { sendPhoneCodeByPhone } from "./phoneVerification";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUser = (token, dispatch) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  dispatch(setUser({ verified_phone: null }));
  axios
    .get(`${BASE_URL}/users/get_user`, {
      headers,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    })
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(resp.data));
      if (!resp.data.verified_phone) dispatch(setUser({ verified_phone: undefined }));
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
}

export const createUser = async (name, email, phone, token, dispatch) => {
  axios
    .post(
      `${BASE_URL}/users/create_user`,
      { phone: phone, name: name, email: email },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((resp) => {
      console.log(resp);
      dispatch(setUser({ ...resp.data.user, verified_phone: undefined }));
      //   setIsLoading(false);
      //   setCurrentView("step3");
      // window.fbq("track", "Lead");
    })
    .catch((e) => {
      console.log(e.response.data.message);
      //   console.log(e);
      // const error = JSON.parse(e.request.response);
      // console.log(error);
    });
  //   try {
  //     const result = await post(
  //       "/users/create_user",
  //       { phone: phone, name: name, email: email },
  //       token
  //     );
  //     console.log("Solicitud POST exitosa:", result);
  //     // setAuthenticated(true);
  //   } catch (error) {
  //     console.error("Error al realizar la solicitud POST:", error.message);
  //   }
};

export const updateUser = async (token, payload, dispatch) => {
  axios
    .post(
      `${BASE_URL}/users/update_user`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(payload));
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
}

// Services by phone

export const getUserByPhone = (phone, setIsLoading, dispatch) => {
  dispatch(setUser({ verified_phone: null }));
  axios
    .get(`${BASE_URL}/users/get_user_by_phone?phone=${phone}`)
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(resp.data));
      dispatch(login(
        undefined,
        resp?.data?.name,
        resp?.data?.email,
        true
      ));
      setIsLoading(false);
    })
    .catch((e) => {
      console.log(e.response.data.message);
      createUserByPhone(undefined, undefined, phone, setIsLoading, dispatch);
    });
};

export const createUserByPhone = async (name, email, phone, setIsLoading, dispatch, setShowLogin) => {
  axios
    .post(
      `${BASE_URL}/users/create_user_by_phone`,
      { phone, name: name ? name : "", email: email ? email : "" },
    )
    .then((resp) => {
      console.log(resp);
      localStorage.setItem("accessToken", resp.data.auth.access_token);
      localStorage.setItem("refreshToken", resp.data.auth.refresh_token);
      dispatch(setUser(resp.data.user));
      dispatch(login(
        undefined,
        resp?.data?.user?.name,
        resp?.data?.user?.email,
        false,
        resp.data.auth.access_token,
        resp.data.auth.refresh_token
      ));
      dispatch(setUser({ verified_phone: undefined }));
      sendPhoneCodeByPhone(phone, setIsLoading, setShowLogin);
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

export const updateUserByPhone = (payload, dispatch) => {
  axios
    .post(`${BASE_URL}/users/update_user_by_phone`, payload)
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(payload));
      dispatch(login(
        undefined,
        payload.name,
        payload.email,
        true,
        undefined
      ));
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

export const checkUserExistence = async (phone, setShowLogin, setIsLoading, dispatch) => {
  if(dispatch) dispatch(setUser({ verified_phone: null }));
  return axios
    .get(`${BASE_URL}/users/check_user_existence?phone=${phone}`)
    .then((resp) => {
      console.log(resp);
      if(setShowLogin){
        dispatch(setUser({ phone }));
        if (resp.data.exists) {
          sendPhoneCodeByPhone(phone, setIsLoading, setShowLogin);
        } else {
          dispatch(setUser({ verified_phone: null }));
          setIsLoading(false);
        };
      } else {
        if (resp.data.exists) return true;
        if (!resp.data.exists) return false;
      };
    })
    .catch((e) => {
      console.log(e.response.data.message);
      return true;
    });
};

export const reCheckUserExistence = (phone, dispatch) => {
  axios
    .get(`${BASE_URL}/users/check_user_existence?phone=${phone}`)
    .then((resp) => {
      if (resp.data.exists) {
        dispatch(setUser({ name_user_exist: resp.data.name }));
      } else {
        dispatch(setUser({ name_user_exist: "" }));
      }
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

export const checkEmailAvailability = async (email) => {
  return axios
    .get(`${BASE_URL}/users/email_availability?email=${email}`)
    .then((resp) => {
      console.log(resp);
      return resp.data.valid;
    })
    .catch((e) => {
      console.log(e.response.data.message);
      return false;
    });
};

export const activeUserServer = async () => {
  axios
    .get(`${BASE_URL}/users/activate`)
    .then((resp) => {
      console.log(resp);
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

export const checkToken = async (dispatch) => {
  dispatch(setLoadingSession(true));
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  if (accessToken) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    await axios
      .get(`${BASE_URL}/users/get_user`, {
        headers,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      })
      .then((resp) => {
        console.log(resp);
        dispatch(setUser(resp.data));
        dispatch(login(undefined, resp.data.name, resp.data.email, true, accessToken, refreshToken));
      })
      .catch((e) => {
        console.log(e.response.data.message);
        if (e.response.data.message === "Token expirado") {
          axios
            .post(`${BASE_URL}/tokens/refresh_token?refresh_token=${refreshToken}`)
            .then((resp) => {
              console.log(resp);
              localStorage.setItem("accessToken", resp.data.access_token);
              dispatch(checkToken(dispatch));
            })
            .catch((e) => {
              console.log(e.response.data.message);
              if (e.response.data.message === "Token de actualización expirado") {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
              }
            })
        }
      });
  }
  dispatch(setLoadingSession(false));
};

export const refreshUser = () => ({
  type: types.refreshUser
});

export const setUser = (data) => ({
  type: types.setUser,
  payload: data,
});
