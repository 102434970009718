import React from 'react';
import '../styles/components/basePurpleButton.css'

export default function BasePurpleButtonComponent({ buttonText, buttonDisabled, onClick, width, type }) {
  return (
    <button className="purple-button" disabled={buttonDisabled} onClick={onClick} style={{ width }} type={type}>
      {buttonText}
    </button>
  )
}
