import "../styles/components/baseRadioButton.css";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useMediaQuery } from "../hooks/useMediaQuery";

export default function BaseRadioButtonGroup({
  optionChecked,
  setOptionChecked,
  kms,
  version_id,
  setShowModalStopper,
  type,
  isAutaMail,
  setShowDropdownKms,
  setShowDropdownSale,
  setButtonDisabled,
  handleButtonClick,
  title
}) {

  let isPageWide = useMediaQuery("(min-width: 1000px)");

  const handleOptionChange = (e) => {
    if (type === "cotiza") {
      setButtonDisabled(false);
      setOptionChecked(e.target.value);
    } else {
      const intValue = parseInt(kms.replace(/\./g, ""), 10); // Convierte a entero y elimina separadores de miles
      axios
        .get(
          `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/is_stopper?idAuta=${version_id}&kms=${intValue}&gnc=${optionChecked === "No" ? false : true
          }`
        )
        .then((resp) => {
          if (resp.data.stopper === true) {
            setShowModalStopper(true);
          } else {
            setOptionChecked(e.target.value);
            if(handleButtonClick) handleButtonClick(6);
            if (isAutaMail) {
              setShowDropdownKms(false);
              setShowDropdownSale(true);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div>
      <p className="radio-button-title mt-1" style={{ minWidth: !isPageWide? "200px": "" }} >{title}</p>
      <div className="radio-button-container">
        <Form.Check
          className="radio-button-input"
          type="radio"
          label="Si"
          value="si"
          checked={optionChecked === "si"}
          onChange={handleOptionChange}
        />

        <Form.Check
          className="radio-button-input"
          type="radio"
          label="No"
          value="no"
          checked={optionChecked === "no"}
          onChange={handleOptionChange}
        />
      </div>
    </div>
  );
}
