import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { sendPhoneCode } from "../../../../actions/phoneVerification";
import { useNavigate } from "react-router-dom";
import { sendCotizacion } from "../../service/cotizar";
import { useSpinner } from "../../../../context/spinnerContext";
import SpinnerComponent from "../../../../components/SpinnerComponent";
import IconEdit from "../../images/icon-edit.svg";
import IconClose from "../../images/icon-close.svg";
import { fetchAreaCodes } from "../../../../actions/areaCodes";
import "../../../../styles/components/baseInput.css"
import ModalInterior from "../Modals/ModalInterior";
import { checkEmailAvailability, checkUserExistence } from "../../../../actions/user";

export const ViewStepOneComponent = ({
  selectedOptions,
  isGnc,
  kms,
  setCurrentView,
  setPriceQuote,
  setPhoneNumber,
  cotiza
}) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const { token, name, email } = useSelector((state) => state.authReducer);
  const { phone, verified_phone } = useSelector((state) => state.userReducer);
  const { register, handleSubmit, setValue, getValues } = useForm();
  const [codArea, setcodArea] = useState(8);
  const [areaCodes, setAreaCodes] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoading, hideLoading, showSpinner } = useSpinner();
  const [areaCodesOpen, setAreaCodesOpen] = useState(false);
  const [showModalInterior, setShowModalInterior] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    if (verified_phone === null) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [verified_phone]);

  useEffect(() => {
    setValue("name", name);
    setValue("email", email);
  }, []);

  const handleChange = (e, inputName) => {
    setValue(inputName, e.target.value);
    setErrorMessage(false);
  };

  const sendSale = async (data) => {
    setIsLoading(true);
    if (verified_phone) {
      sendCotizacion(
        selectedOptions?.brand?.name,
        selectedOptions?.brand?.id,
        selectedOptions?.model?.name,
        selectedOptions?.model?.id,
        selectedOptions?.version?.name,
        selectedOptions?.version?.id,
        selectedOptions?.year?.name,
        isGnc,
        kms,
        phone,
        name,
        email ?? "",
        "si",
        setIsLoading,
        setCurrentView,
        setPriceQuote,
        navigate,
        dispatch
      );
    } else {
      const completePhone = phone ? phone : `549${data.cod_area}${data.telefono}`;
      const userExists = await checkUserExistence(completePhone);
      if (userExists && !phone) {
        setErrorMessage("El teléfono ya posee una cuenta.")
        setIsLoading(false);
      } else {
        if (setPhoneNumber) setPhoneNumber(completePhone);
        const channel = "compras";
        sendPhoneCode(token, channel, completePhone, dispatch);
        setTimeout(() => {
          setCurrentView("step2");
          setIsLoading(false);
        }, 2000);
      }
    }
  };

  const sendAutaSale = async () => {
    setIsLoading(true);
    if (getValues("email")) {
      const validMail = await checkEmailAvailability(getValues("email"));
      if (validMail) {
        sendCotizacion(
          selectedOptions?.brand?.name,
          selectedOptions?.brand?.id,
          selectedOptions?.model?.name,
          selectedOptions?.model?.id,
          selectedOptions?.version?.name,
          selectedOptions?.version?.id,
          selectedOptions?.year?.name,
          isGnc,
          kms,
          getValues("telefono") ? `549${getValues("cod_area")}${getValues("telefono")}` : phone,
          getValues("name"),
          getValues("email") ?? "",
          cotiza,
          setIsLoading,
          setCurrentView,
          setPriceQuote,
          navigate,
          dispatch
        );
      } else {
        setErrorMessage("El mail ya posee una cuenta.");
        hideLoading();
        setIsLoading(false);
      }
    } else {
      sendCotizacion(
        selectedOptions?.brand?.name,
        selectedOptions?.brand?.id,
        selectedOptions?.model?.name,
        selectedOptions?.model?.id,
        selectedOptions?.version?.name,
        selectedOptions?.version?.id,
        selectedOptions?.year?.name,
        isGnc,
        kms,
        getValues("telefono") ? `549${getValues("cod_area")}${getValues("telefono")}` : phone,
        getValues("name"),
        getValues("email") ?? "",
        cotiza,
        setIsLoading,
        setCurrentView,
        setPriceQuote,
        navigate,
        dispatch
      );
    }
  };

  const handleChangeAreaCode = async (e) => {
    if (e.target.value === "") {
      setAreaCodesOpen(false);
    } else {
      setAreaCodesOpen(true);
    }
    setcodArea(e.target.value.length);
    const a = await fetchAreaCodes(e.target.value);
    setAreaCodes(a);
  };

  const handleClickAreaCode = (code, city) => {
    setAreaCodesOpen(false);
    setValue("cod_area", code);
    if (city !== "AMBA" && city !== "Bs. As." && setShowModalInterior) {
      setShowModalInterior(true);
    };
  };

  const handleEventLeft = () => {
    window.location.reload();
  };

  const handleEventRight = () => {
    setShowModalInterior(false);
  };


  return (
    <>
      <div
        className={
          isPageWide ? "card-contact-data" : "mobile-dropdown-container"
        }
      >
        <div className="card-contact-header">
          <p className="contact-header-title">
            {verified_phone ?
              "¡Estos son tus datos!"
              :
              "¡Completá tus datos!"
            }
          </p>
        </div>
        {showSpinner ? (
          <div style={{ marginTop: "50px" }}>
            <SpinnerComponent />
          </div>
        ) : (
          <>
            <div style={{ padding: "12px", height: "364px" }}>
              <form
                onSubmit={handleSubmit(email?.includes("@auta.com.ar") ? sendAutaSale : sendSale)}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {email?.includes("@auta.com.ar") ?
                  <div>
                    <div>
                      <label className="input-labels">Nombre</label>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {editName ?
                          <>
                            <input
                              className="base-input"
                              {...register("name", {
                                required: true,
                              })}
                              placeholder="Ingresá el nombre"
                              onChange={(e) => handleChange(e, "name")}
                              style={{ height: "30px" }}
                            />
                            <img
                              src={IconClose}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              className=""
                              onClick={() => setEditName(false)}
                            />
                          </>
                          :
                          <>
                            <p style={{ height: "30px", alignContent: "center" }}>{getValues("name") ?? name}</p>
                            <img
                              src={IconEdit}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              className=""
                              onClick={() => setEditName(true)}
                            />
                          </>
                        }
                      </div>
                    </div>
                    <div>
                      <label className="input-labels">Email</label>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {editEmail ?
                          <>
                            <input
                              className="base-input"
                              {...register("email", {
                                required: false,
                              })}
                              placeholder="Ingresá el mail"
                              onChange={(e) => handleChange(e, "email")}
                              style={{ height: "30px" }}
                            />
                            <img
                              src={IconClose}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              onClick={() => setEditEmail(false)}
                            />
                          </>
                          :
                          <>
                            <p style={{ height: "30px", alignContent: "center" }}>{getValues("email") ?? email}</p>
                            <img
                              src={IconEdit}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              onClick={() => setEditEmail(true)}
                            />
                          </>
                        }
                      </div>
                    </div>
                    <div>
                      <label className="input-labels">Teléfono celular</label>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {editPhone || !phone ?
                          <>
                            <label className="phone-number" style={{ height: "30px", alignContent: "center", fontSize: "16px" }}>+549</label>
                            <input
                              type="text"
                              className="base-input"
                              placeholder="Ej: 11"
                              maxLength={3}
                              style={{ width: "65px", fontSize: "16px" }}
                              {...register("cod_area", {
                                required: true,
                              })}
                              onChange={handleChangeAreaCode}
                              autoComplete="off"
                            />
                            {areaCodesOpen &&
                              <div className="base-input area-codes-container">
                                {areaCodes?.map((code) => {
                                  for (let key in code) {
                                    return (
                                      <div key={code[key]} className="area-codes-option" onClick={() => handleClickAreaCode(code[key], key)}>
                                        <label className="area-codes-number">{code[key]}</label>
                                        <label className="area-codes-city">{key}</label>
                                      </div>
                                    )
                                  }
                                })}
                              </div>
                            }
                            <input
                              type="text"
                              className="base-input"
                              placeholder="Sin 15"
                              {...register("telefono", {
                                required: true,
                                maxLength: 10,
                                minLength: 6
                              })}
                              style={{ fontSize: "16px" }}
                              onChange={(e) => handleChange(e, "telefono")}
                            />
                            {phone &&
                              <img
                                src={IconClose}
                                alt="edit-icon"
                                style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                                onClick={() => setEditPhone(false)}
                              />
                            }
                          </>
                          :
                          <>
                            <p style={{ height: "30px", alignContent: "center" }}>{getValues("telefono") ? `+549${getValues("cod_area")}${getValues("telefono")}` : phone}</p>
                            <img
                              src={IconEdit}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              onClick={() => setEditPhone(true)}
                            />
                          </>
                        }
                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <div style={{ marginBottom: "12px" }}>
                      <label className="input-labels">Nombre</label>
                      <p>{name}</p>
                    </div>
                    <div style={{ marginBottom: "12px" }}>
                      <label className="input-labels">Email</label>
                      <p>{email}</p>
                    </div>
                    <div style={{ marginBottom: "12px" }}>
                      <label className="input-labels">Teléfono celular</label>
                      {verified_phone ?
                        <p>+{phone}</p>
                        :
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p className="phone-number">+549</p>
                          <input
                            type="text"
                            className="base-input"
                            placeholder="Ej: 11"
                            maxLength={3}
                            style={{ width: "65px", marginRight: "10px" }}
                            {...register("cod_area", {
                              required: true,
                            })}
                            onChange={handleChangeAreaCode}
                            autoComplete="off"
                          />
                          {areaCodesOpen &&
                            <div className="base-input area-codes-container">
                              {areaCodes?.map((code) => {
                                for (let key in code) {
                                  return (
                                    <div key={code[key]} className="area-codes-option" onClick={() => handleClickAreaCode(code[key], key)}>
                                      <label className="area-codes-number">{code[key]}</label>
                                      <label className="area-codes-city">{key}</label>
                                    </div>
                                  )
                                }
                              })}
                            </div>
                          }
                          <input
                            type="text"
                            className="base-input ms-1"
                            placeholder="Sin 15"
                            {...register("telefono", {
                              required: true,
                              maxLength: 10,
                              minLength: 6,
                            })}
                            maxLength={10}
                            minLength={6}
                          />
                        </div>
                      }
                    </div>
                  </div>
                }
                <div className="d-flex flex-column justify-content-between align-items-center">
                  {!verified_phone &&
                    <p className="security-code-text">
                      Recibirás un código de seguridad.
                    </p>
                  }
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      style={{
                        margin: "10px",
                        color: " #7340d8",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <BasePurpleButtonComponent
                    buttonText={isLoading ? "Enviando..." : "Continuar"}
                    buttonDisabled={isLoading ? true : false}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </>
        )}
      </div>
      <ModalInterior
        setShow={setShowModalInterior}
        show={showModalInterior}
        title={"¿Vivís en el interior del país?"}
        subtitle={
          isPageWide ?
            "Por el momento, solo realizamos inspecciones en la\nCiudad Autónoma de Buenos Aires (CABA)."
            :
            "Por el momento, solo realizamos\ninspecciones en la Ciudad Autónoma\nde Buenos Aires (CABA)."
        }
        subtitleTwo={"Agradecemos tu comprensión"}
        textBtnLeft={"Entiendo :("}
        textBtnRight={"¡Avancemos :)!"}
        handleEventLeft={handleEventLeft}
        handleEventRight={handleEventRight}
      />
    </>
  );
};
