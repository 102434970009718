import React from "react";
import { Modal } from "react-bootstrap";
import "../styles/components/modalLowKms.css";
import { useSelector } from "react-redux";
import { useMediaQuery } from "../hooks/useMediaQuery";

export default function ModalMinKmsComponent({
  show,
  setShow,
  kms,
  setIsContinuing,
  setSelectedButton,
  setCurrentView,
  isMobile,
  setIsModalOpen
}) {

  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const { isAuthenticated } = useSelector((state) => state.authReducer);

  const handleClose = () => setShow(false);
  const HandleYesButton = () => {
    setIsContinuing(true);
    if (!isAuthenticated) {
      setIsModalOpen(true);
    } else {
      if (isMobile) {
        setSelectedButton(6);
      } else {
        setCurrentView("step3");
      }
    }
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose} size="md" dialogClassName={isPageWide ? "modal-interior" : "modal-interior-mobile"} centered>
      <Modal.Body
        style={{ width: "100%", paddingTop: "30px" }}

      >
        <div
          /* style={{ height: "90%" }} */
          className="d-flex align-items-center justify-content-between flex-column"
        >
          <p className="title-modal-interior" style={{ fontSize: isPageWide ? "30px" : "22px" }}>
            El kilometraje ingresado es muy bajo
          </p>
          {/* <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="value-kms-modal mt-2 mb-2">
              Ingresaste <strong> {kms}</strong> kms
            </p>
            <p className="ask-kms-modal mb-5">¿Es correcto?</p>
          </div> */}
          {isPageWide ?
            <p className="subtitle-modal-interior mt-3 mb-2">
              Ingresaste <strong> {kms}</strong> kms
            </p>
            :
            <p className="subtitle-modal-interior mt-4 mb-3">
              Ingresaste <strong> {kms}</strong> kms
            </p>
          }
          <p className="subtitle-2-modal-interior mb-4">
            ¿Es correcto?
          </p>
          <div className={`d-flex align-items-center justify-content-around flex-${isPageWide ? "row" : "column"} mt-5`}>
            <button className={`btn-purple-kms-interior`} onClick={handleClose}>
              No
            </button>
            <button className={`btn-white-kms-interior ${isPageWide ? "ms-5" : "mt-3"}`} onClick={HandleYesButton}>
              Si
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
