import { types } from "../types/types";

const initialState = {
  loadingSession: true
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        uid: action.payload.uid,
        name: action.payload.displayName,
        email: action.payload.email,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken ?? undefined,
        phone: action.payload.phone,
        verified_phone: action.payload.verified_phone
      };

    case types.setLoadingSession:
      return {
        ...state,
        loadingSession: action.payload.loadingSession,
      };

    case types.setIsAuth:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      };

    case types.refreshAuth:
      return {
        ...state,
        uid: undefined,
        name: undefined,
        email: undefined,
        isAuthenticated: undefined,
        token: undefined,
        refreshToken: undefined,
        phone: undefined,
        verified_phone: undefined
      };

    default:
      return state;
  }
};
