import axios from "axios";
import { types } from "../types/types";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchAreaCodes = async (number) => {
  return axios
    .get(`${BASE_URL}/users/area_code?search_input=${number}`)
    .then((resp) => {
      console.log(resp)
      return resp.data.search_results
    })
    .catch((e) => {
      console.log(e.response.data.message);
      return []
    });
};

export const getAreaCodes = () => ({
  type: types.getAreaCodes
});

export const setAreaCodes = (data) => ({
  type: types.setAreaCodes,
  payload: data,
});
