import React from "react";
import { Modal } from "react-bootstrap";
import "../../style/modalInterior.css";
import { MdOutlineClose } from "react-icons/md";
import { TitleHomeWithIcon } from "../../../Home/components/TitleHomeWithIcon";
import { colors } from "../../../../styles/colors";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";

const ModalInterior = ({
  show,
  setShow,
  title,
  subtitle,
  subtitleTwo,
  textBtnLeft,
  textBtnRight,
  handleEventLeft,
  handleEventRight
}) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName={isPageWide ? "modal-interior" : "modal-interior-mobile"}
      centered
      style={{ whiteSpace: "pre-wrap" }}
    >
      <Modal.Body style={{ width: "100%", paddingTop: "30px" }}>
        <div
          /* style={{ height: "90%" }} */
          className="d-flex align-items-center justify-content-between flex-column"
        >
          <div style={{ fontSize: isPageWide ? "20px" : "16px" }}>
            <p className="title-modal-interior" style={{ fontSize: isPageWide ? "30px" : "22px" }}>
              {title}
            </p>
            {isPageWide ?
              <p className="subtitle-modal-interior mt-4 mb-2">
                {subtitle}
              </p>
              :
              <p className="subtitle-modal-interior mt-4 mb-3">
                {subtitle}
              </p>
            }
            <p className="subtitle-2-modal-interior mb-4">
              {subtitleTwo}
            </p>
          </div>
          <div
            className={`d-flex align-items-center justify-content-around flex-${isPageWide ? "row" : "column"} mt-5`}
          /* style={{ height: "52vh" }} */
          >
            <button className="btn-purple-kms-interior" onClick={handleEventLeft}>
              {textBtnLeft}
            </button>
            <button className={`btn-white-kms-interior ${isPageWide ? "ms-5" : "mt-3"}`} onClick={handleEventRight}>
              {textBtnRight}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInterior;
