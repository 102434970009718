import { types } from "../types/types";

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case types.setUser:
      return {
        ...state,
        ...action.payload
      };

    case types.refreshUser:
      return {
        name: undefined,
        email: undefined,
        phone: undefined,
        verified_phone: undefined,
        name_user_exist: undefined,
      };

    default:
      return state;
  }
};
