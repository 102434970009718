import React, { useState } from "react";
import BaseModalAuthComponent from "./BaseModalAuthComponent";
import LoginComponent from "./LoginComponent";
import RegisterComponent from "./RegisterComponent";
import { TitleHomeWithIcon } from "../../Home/components/TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { ModalVerificationPhone } from "../../Cotizador/components/Modals/ModalVerificationPhone";
import { useSelector } from "react-redux";

const ModalAuthComponent = ({
  setIsModalOpen,
  isModalOpen,
  toggleLoginModal,
  getInWithEmail,
  setGetInWithEmail,
  showLogin,
  setShowLogin,
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setPriceQuote,
  successRedirect
}) => {

  let isPageWide = useMediaQuery("(min-width: 630px)");
  const [isLoading, setIsLoading] = useState();
  const { name_user_exist } = useSelector((state) => state.userReducer);

  return (
    <BaseModalAuthComponent
      show={isModalOpen}
      onClose={toggleLoginModal}
      getInWithEmail={getInWithEmail}
      setGetInWithEmail={setGetInWithEmail}
      setShowLogin={setShowLogin}
      isLoading={isLoading}
    >
      <div className="mt-1 mb-2 mb-md-1">
        <TitleHomeWithIcon
          title={
            !showLogin
              ? "Crear cuenta"
              : name_user_exist !== ""
              ? `¡Hola ${name_user_exist}!`
              : "Iniciar sesión"
          }
          fontSize={isPageWide ? "30px" : "22px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      </div>
      {!showLogin ? (
        <RegisterComponent
          setCurrentView={setCurrentView}
          isSale={isSale}
          isSaleMobile={isSaleMobile}
          handleButtonClick={handleButtonClick}
          setIsModalOpen={setIsModalOpen}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          successRedirect={successRedirect}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        <>
          <ModalVerificationPhone
            setCurrentView={setCurrentView}
            setIsModalOpen={setIsModalOpen}
            textError={"Código inválido"}
            setPriceQuote={setPriceQuote}
            successRedirect={successRedirect}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleButtonClick={handleButtonClick}
          />
        </>
      )}
    </BaseModalAuthComponent>
  );
};

export default ModalAuthComponent;
