export const types = {
  getQuote: "[Quote] Get Quote",
  setQuote: "[Quote] Set Quote",
  setUser: "[User] Set User",
  refreshUser: "[User] Refresh User",
  login: "[Auth] Login",
  logout: "[Auth] Logout",
  setIsAuth: "[Auth] Set isAuthenticated",
  refreshAuth: "[Auth] Refresh Auth Data",
  setLoadingSession: "[Auth] Set Loading Session"
};
