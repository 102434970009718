import React, { useEffect, useState } from "react";
import CardBaseAuthComponent from "./CardBaseAuthComponent";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { startGoogleLogin, startRegisterWithEmailPasswordName } from "../../../actions/auth";
import GoogleIcon from "../images/google-icon.svg";
import { checkEmailAvailability, checkUserExistence, createUserByPhone } from "../../../actions/user";
import SpinnerComponent from "../../../components/SpinnerComponent";
import BasePurpleButtonComponent from "../../../components/BasePurpleButtonComponent";
import { useNavigate } from "react-router-dom";
import { fetchAreaCodes } from "../../../actions/areaCodes";
import ModalInterior from "../../Cotizador/components/Modals/ModalInterior";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { colors } from "../../../styles/colors";

const RegisterComponent = ({
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen,
  showLogin,
  setShowLogin,
  successRedirect,
  isLoading,
  setIsLoading
}) => {

  const handleShowLogin = () => [setShowLogin(true)];
  const { token, name, email, isAuthenticated } = useSelector((state) => state.authReducer);
  const { phone, verified_phone } = useSelector((state) => state.userReducer);
  const [codArea, setcodArea] = useState(8);
  const [isDisabled, setIsDisabled] = useState(true);
  const [areaCodes, setAreaCodes] = useState([]);
  const { register, handleSubmit, getValues, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const [errorAuth, setErrorAuth] = useState("");
  const navigate = useNavigate();
  const [areaCodesOpen, setAreaCodesOpen] = useState(false);
  const [showModalInterior, setShowModalInterior] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  let isPageWide = useMediaQuery("(min-width: 1000px)");

  /* useEffect(() => {
    if (verified_phone === null) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [verified_phone]); */

  useEffect(() => {
    reset();
    if (phone) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [phone, showLogin]);

  // Función para detectar si el navegador es un navegador-en-app
  const isInAppBrowser = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.includes("Instagram") || ua.includes("FBAN") || ua.includes("FBAV")
    );
  };

  const googleLogin = () => {
    if (isInAppBrowser()) {
      const androidUrl = `intent://${window.location.host}${window.location.pathname}#Intent;scheme=https;package=com.android.chrome;end`;
      window.location.href = androidUrl;
    } else {
      dispatch(
        startGoogleLogin(
          setCurrentView,
          isSale,
          isSaleMobile,
          handleButtonClick,
          setIsModalOpen,
          successRedirect,
          navigate
        )
      );
    }
  };

  const sendSale = async (data) => {
    setIsLoading(true);
    const newPhone = `549${data.cod_area}${data.telefono}`;
    if (getValues("email")) {
      const validMail = await checkEmailAvailability(getValues("email"));
      if (validMail) {
        if (phone) {
          createUserByPhone(getValues("name"), getValues("email"), phone, setIsLoading, dispatch, setShowLogin);
        } else {
          checkUserExistence(data.telefono ? newPhone : phone, setShowLogin, setIsLoading, dispatch);
        };
      } else {
        setErrorMessage("El mail ya posee una cuenta.");
        setIsLoading(false)
      }
    } else {
      if (phone) {
        createUserByPhone(getValues("name"), getValues("email"), phone, setIsLoading, dispatch, setShowLogin);
      } else {
        checkUserExistence(data.telefono ? newPhone : phone, setShowLogin, setIsLoading, dispatch);
      };
    }
  };

  const registerUser = (data) => {
    dispatch(
      startRegisterWithEmailPasswordName(
        data.email,
        data.password,
        data.name,
        setErrorAuth,
        setCurrentView,
        isSale,
        isSaleMobile,
        handleButtonClick,
        setIsModalOpen
      )
    );
  };

  const onChangeName = (e) => {
    if (e.target.value) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  };

  const handleChangeAreaCode = async (e) => {
    if (e.target.value === "") {
      setAreaCodesOpen(false);
    } else {
      setAreaCodesOpen(true);
    }
    setcodArea(e.target.value.length);
    const a = await fetchAreaCodes(e.target.value);
    setAreaCodes(a);
  };

  const handleClickAreaCode = (code, city) => {
    setAreaCodesOpen(false);
    setValue("cod_area", code);
    if (city !== "AMBA" && city !== "Bs. As." && setShowModalInterior) {
      setShowModalInterior(true);
    };
  };

  const handleEventLeft = () => {
    window.location.reload();
  };

  const handleEventRight = () => {
    setShowModalInterior(false);
  };

  return (
    <>
      <CardBaseAuthComponent text={"Completá todos los campos"}>
        {isLoading ? (
          <div style={{ marginTop: "50px", minHeight: "250px" }}>
            <SpinnerComponent />
          </div>
        ) : (
          <form onSubmit={handleSubmit(sendSale)} className="d-flex flex-column" style={{ height: "300px" }}>
            <div className="d-flex flex-column" style={{ height: "100%", justifyContent: "space-between" }}>
              <div style={{ padding: "20px 5px", width: "100%" }}>
                <label className="input-labels">Teléfono celular</label>
                {phone ?
                  <p>+{phone}</p>
                  :
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="phone-number">+549</p>
                    <input
                      type="text"
                      className="base-input"
                      placeholder="Ej: 11"
                      maxLength={4}
                      style={{ width: "65px" }}
                      {...register("cod_area", {
                        required: true,
                      })}
                      onChange={handleChangeAreaCode}
                      autoComplete="off"
                    />
                    {areaCodesOpen &&
                      <div className="base-input area-codes-container">
                        {areaCodes?.map((code) => {
                          for (let key in code) {
                            return (
                              <div key={code[key]} className="area-codes-option" onClick={() => handleClickAreaCode(code[key], key)}>
                                <label className="area-codes-number">{code[key]}</label>
                                <label className="area-codes-city">{key}</label>
                              </div>
                            )
                          }
                        })}
                      </div>
                    }
                    <input
                      type="text"
                      className="base-input ms-1"
                      placeholder="Sin 15"
                      {...register("telefono", {
                        required: true,
                        maxLength: codArea ? Math.abs(codArea - 10) : 0,
                        minLength: codArea ? Math.abs(codArea - 10) : 0,
                      })}
                      maxLength={
                        codArea ? Math.abs(parseInt(codArea.length) - 10) : 0
                      }
                      minLength={
                        codArea ? Math.abs(parseInt(codArea.length) - 10) : 0
                      }
                    />
                  </div>
                }
                {phone &&
                  <>
                    <div className="register-input mb-2 mt-2">
                      <label className="input-labels">Nombre</label>
                      {name ?
                        <p>{name}</p>
                        :
                        <input
                          type="text"
                          className="base-input"
                          placeholder="Ingresá tu nombre"
                          {...register("name", {
                            required: true,
                            maxLength: 50,
                            minLength: 2,
                          })}
                          onChange={onChangeName}
                        />
                      }
                    </div>
                    <div className="register-input">
                      <label className="input-labels">Email</label>
                      {email ?
                        <p>{email}</p>
                        :
                        <input
                          type="text"
                          className="base-input"
                          placeholder="Ingresá tu email"
                          {...register("email", {
                            maxLength: 30,
                            minLength: 2,
                          })}
                          onChange={() => setErrorMessage("")}
                        />
                      }
                    </div>
                  </>
                }
              </div>
              {errorMessage ?
                <p className="security-code-text mb-3" style={{ color: colors.red.red }}>
                  {errorMessage}
                </p>
                :
                <>
                  {!verified_phone &&
                    <p className="security-code-text mb-3">
                      Recibirás un código de seguridad.
                    </p>
                  }
                </>
              }
              <div
                className="register-button mb-3"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  textAlignLast: "center"
                }}
              >
                <BasePurpleButtonComponent
                  buttonText={isLoading ? "Enviando..." : "Continuar"}
                  buttonDisabled={isDisabled ? true : isLoading}
                  type="submit"
                  width="180px"
                />
              </div>
            </div>
            <p>{errorAuth}</p>
            {!phone && !isLoading &&
              <div className="d-flex align-items-center justify-content-center flex-column mb-3">
                <button
                  className="button-google d-flex align-items-center justify-content-center"
                  onClick={googleLogin}
                >
                  <img src={GoogleIcon} alt="google-icon" className="google-icon" />
                  Ingresar con Google
                </button>
              </div>
            }
          </form>
        )}
        {/* <div className="d-flex align-items-center justify-content-center">
        <div className="division-line"></div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center flex-column mt-3 mb-2"
        onClick={handleShowLogin}
        style={{ cursor: "pointer" }}
      >
        <label className="text-not-account">¿Ya tenés una cuenta en Auta?</label>
        <label className="text-new-account">Iniciar sesión</label>
      </div> */}
      </CardBaseAuthComponent>
      <ModalInterior
        setShow={setShowModalInterior}
        show={showModalInterior}
        title={"¿Vivís en el interior del país?"}
        subtitle={
          isPageWide ?
            "Por el momento, solo realizamos inspecciones en la\nCiudad Autónoma de Buenos Aires (CABA)."
            :
            "Por el momento, solo realizamos\ninspecciones en la Ciudad Autónoma\nde Buenos Aires (CABA)."
        }
        subtitleTwo={"Agradecemos tu comprensión"}
        textBtnLeft={"Entiendo :("}
        textBtnRight={"¡Avancemos :)!"}
        handleEventLeft={handleEventLeft}
        handleEventRight={handleEventRight}
      />
    </>
  );
};

export default RegisterComponent;
