import axios from "axios";
import { setUser, updateUser } from "./user";
import { login, setIsAuth } from "./auth";
import qs from "qs";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const sendPhoneCode = (token, channel, phone, dispatch) => {
  axios
    .post(
      `${BASE_URL}/users/update_user`,
      { phone },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(() => {
      dispatch(setUser({ phone }));
      axios
        .post(
          `${BASE_URL}/users/send_check_code`,
          { channel },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((resp) => {
          console.log(resp);
        })
        .catch((e) => {
          console.log(e.response.data.message);
        });
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
}

export const checkPhoneCode = async (token, code, setisError, dispatch, setIsLoading, setCurrentView, setIsModalOpen, handleButtonClick) => {
  return axios
    .post(
      `${BASE_URL}/users/check_phone`,
      { code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((resp) => {
      console.log(resp);
      updateUser(token, { verified_phone: true }, dispatch);
      dispatch(setIsAuth(true));
      if (setCurrentView) setCurrentView("step3");
      if (setIsModalOpen) setIsModalOpen(false);
      if (handleButtonClick) handleButtonClick(6);
      setisError(false);
      return true
    })
    .catch((e) => {
      console.log(e?.response?.data?.message);
      setisError(true);
      setIsLoading(false);
      return false
    });
}

// Services by phone:

export const sendPhoneCodeByPhone = (phone, setIsLoading, setShowLogin, setIsModalOpen, hideLoading) => {
  axios
    .post(`${BASE_URL}/users/send_check_code_by_phone?channel=compras&phone=${phone}`)
    .then((resp) => {
      console.log(resp);
      if(setIsLoading) setIsLoading(false);
      if(setShowLogin) setShowLogin(true);
      if(setIsModalOpen) setIsModalOpen(true);
      if(hideLoading) hideLoading();
    })
    .catch((e) => {
      console.log(e.response.data.message);
      if(setIsLoading) setIsLoading(false);
    });
}

export const reSendPhoneCodeByPhone = (phone, setIsLoading, setShowLogin) => {
  axios
    .post(
      `${BASE_URL}/users/send_check_code_by_phone?channel=compras&resend=${true}&phone=${phone}`
    )
    .then((resp) => {
      console.log(resp);
      setIsLoading(false);
      setShowLogin(true);
    })
    .catch((e) => {
      console.log(e.response.data.message);
      setIsLoading(false);
    });
};

export const checkPhoneCodeByPhone = async (phone, code, setisError, dispatch, setIsLoading, setCurrentView, setIsModalOpen, successRedirect, navigate, handleButtonClick) => {
  return axios
    .post(`${BASE_URL}/users/check_phone_by_phone?code=${code}&phone=${phone}`)
    .then((resp) => {
      console.log(resp);
      localStorage.setItem("accessToken", resp.data.auth.access_token);
      localStorage.setItem("refreshToken", resp.data.auth.refresh_token);
      const headers = {
        Authorization: `Bearer ${resp.data.auth.access_token}`,
      };
      axios
        .get(`${BASE_URL}/users/get_user`, {
          headers,
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        })
        .then((user) => {
          console.log(user);
          dispatch(login(undefined, user.data.name, user.data.email, true, resp.data.auth.access_token, resp.data.auth.refresh_token));
          if (setCurrentView) setCurrentView("step3");
          if (setIsModalOpen) setIsModalOpen(false);
          if (handleButtonClick) handleButtonClick(6);
          setisError(false);
          setIsLoading(false);
          if(successRedirect) navigate(successRedirect);
          return true
          
        }).catch((e) => {
          console.log(e?.response?.data?.message);
          setisError(true);
          setIsLoading(false);
          return false
        })
    })
    .catch((e) => {
      console.log(e?.response?.data?.message);
      setisError(true);
      setIsLoading(false);
      return false
    });
}
