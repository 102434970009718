import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import { auth, googleProvider } from "../firebase/firebase";
import { types } from "../types/types";
import { firebaseErrorMessages } from "../modules/Auth/utils/errors";
import { createUser, refreshUser, setUser } from "./user";

export const startGoogleLogin = (
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen,
  successRedirect,
  navigate
) => {
  return async (dispatch) => {
    const { user } = await signInWithPopup(auth, googleProvider);
    dispatch(
      login(user.uid, user.displayName, user.email, true, user.accessToken)
    );
    createUser(user.displayName, user.email, "", user.accessToken, dispatch);
    if (isSale) {
      setCurrentView("step3");
    } else if (isSaleMobile) {
      setCurrentView("step1");
      handleButtonClick(6);
    };
    if(successRedirect) navigate(successRedirect);
    setIsModalOpen(false);
  };
};

export const startLoginEmailPassword = (
  email,
  password,
  setErrorAuth,
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen
) => {
  return async (dispatch) => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      dispatch(setUser({ verified_phone: undefined }));
      dispatch(
        login(user.uid, user.displayName, user.email, true, user.accessToken)
      );
      if (isSale) {
        setCurrentView("step3");
      } else if (isSaleMobile) {
        setCurrentView("step1");
        handleButtonClick(6);
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error during sign in:", error);
      const errorMessage =
        firebaseErrorMessages[error.code] || firebaseErrorMessages["default"];
      setErrorAuth(errorMessage);
    }
  };
};

export const startRegisterWithEmailPasswordName = (
  email,
  password,
  name,
  setErrorAuth,
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
  setIsModalOpen
) => {
  return async (dispatch) => {
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await updateProfile(user, { displayName: name });
      dispatch(login(user.uid, user.displayName, user.email, true, user.accessToken));
      createUser(user.displayName, user.email, "", user.accessToken, dispatch);
      if (isSale) {
        setCurrentView("step3");
      } else if (isSaleMobile) {
        setCurrentView("step1");
        handleButtonClick(6);
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error during registration:", error);
      const errorMessage =
        firebaseErrorMessages[error.code] || firebaseErrorMessages["default"];
      setErrorAuth(errorMessage);
    }
  };
};

export const login = (uid, displayName, email, isAuthenticated, token, refreshToken) => ({
  type: types.login,
  payload: {
    uid,
    displayName,
    email,
    isAuthenticated,
    token,
    refreshToken: refreshToken ?? undefined
  },
});

export const startLogout = (dispatch) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  signOut(auth);
  dispatch(refreshAuth());
  dispatch(refreshUser());
};

export const refreshAuth = () => ({
  type: types.refreshAuth
});

export const setIsAuth = (isAuthenticated) => ({
  type: types.setIsAuth,
  payload: {
    isAuthenticated,
  },
});

export const setLoadingSession = (loadingSession) => ({
  type: types.setLoadingSession,
  payload: {
    loadingSession,
  },
});
