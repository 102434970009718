import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { sendPhoneCode } from "../../../../actions/phoneVerification";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../../../../components/SpinnerComponent";
import { useSpinner } from "../../../../context/spinnerContext";
import IconClose from "../../images/icon-close.svg";
import IconEdit from "../../images/icon-edit.svg";
import { checkEmailAvailability, checkUserExistence, updateUser } from "../../../../actions/user";
import ModalInterior from "./ModalInterior";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { fetchAreaCodes } from "../../../../actions/areaCodes";
import { colors } from "../../../../styles/colors";
import { sendCotizacion } from "../../service/cotizar";

export const ModalAuthenticated = ({
  channel,
  handleSubmitWithUser,
  user,
  textError,
  dataCar,
  kms,
  isGnc,
  setCurrentView,
  setPriceQuote,
  setPhoneNumber,
  cotiza
}) => {
  const { token, name, email } = useSelector((state) => state.authReducer);
  const { phone, verified_phone } = useSelector((state) => state.userReducer);
  const { register, handleSubmit, setValue, getValues } = useForm();
  const [codArea, setcodArea] = useState(8);
  const [areaCodes, setAreaCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoading, hideLoading, showSpinner } = useSpinner();
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [areaCodesOpen, setAreaCodesOpen] = useState(false);
  const [showModalInterior, setShowModalInterior] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  let isPageWide = useMediaQuery("(min-width: 1000px)");

  useEffect(() => {
    if (verified_phone === null) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [verified_phone]);

  const handleChange = (e, inputName) => {
    setValue(inputName, e.target.value);
    setErrorMessage(false);
  };

  const sendCotiza = async (data) => {
    setIsLoading(true);
    if (verified_phone) {
      sendCotizacion(
        dataCar?.brand?.name,
        dataCar?.brand?.id,
        dataCar?.model?.name,
        dataCar?.model?.id,
        dataCar?.version?.name,
        dataCar?.version?.id,
        dataCar?.year?.name,
        isGnc,
        kms,
        phone,
        name,
        email,
        "si",
        setIsLoading,
        setCurrentView,
        setPriceQuote,
        navigate,
        dispatch
      );
    } else {
      const completePhone = phone ? phone : `549${data.cod_area}${data.telefono}`;
      const userExists = await checkUserExistence(completePhone);
      if (userExists && !phone) {
        setErrorMessage("El teléfono ya posee una cuenta.")
        setIsLoading(false);
      } else {
        if (setPhoneNumber) setPhoneNumber(completePhone);
        sendPhoneCode(token, channel, completePhone, dispatch);
        setTimeout(() => {
          setCurrentView("step2");
          setIsLoading(false);
        }, 2000);
      }
    }
  };

  const sendAutaSale = async (data) => {
    setIsLoading(true);
    if (data.telefono) {
      updateUser(token, { phone }, dispatch);
    };
    if (getValues("email") && getValues("email") !== email) {
      const validMail = await checkEmailAvailability(getValues("email"));
      if (validMail) {
        sendCotizacion(
          dataCar?.brand?.name,
          dataCar?.brand?.id,
          dataCar?.model?.name,
          dataCar?.model?.id,
          dataCar?.version?.name,
          dataCar?.version?.id,
          dataCar?.year?.name,
          isGnc,
          kms,
          getValues("telefono") ? `549${getValues("cod_area")}${getValues("telefono")}` : phone,
          getValues("name")? getValues("name"): name,
          getValues("email"),
          cotiza,
          setIsLoading,
          setCurrentView,
          setPriceQuote,
          navigate,
          dispatch
        );
      } else {
        setErrorMessage("El mail ya posee una cuenta.");
        hideLoading();
        setIsLoading(false);
      }
    } else {
      sendCotizacion(
        dataCar?.brand?.name,
        dataCar?.brand?.id,
        dataCar?.model?.name,
        dataCar?.model?.id,
        dataCar?.version?.name,
        dataCar?.version?.id,
        dataCar?.year?.name,
        isGnc,
        kms,
        getValues("telefono") ? `549${getValues("cod_area")}${getValues("telefono")}` : phone,
        getValues("name")? getValues("name"): name,
        email,
        cotiza,
        setIsLoading,
        setCurrentView,
        setPriceQuote,
        navigate,
        dispatch
      );
    }
  };

  const handleChangeAreaCode = async (e) => {
    if (e.target.value === "") {
      setAreaCodesOpen(false);
    } else {
      setAreaCodesOpen(true);
    }
    setcodArea(e.target.value.length);
    const a = await fetchAreaCodes(e.target.value);
    setAreaCodes(a);
  };

  const handleClickAreaCode = (code, city) => {
    setAreaCodesOpen(false);
    setValue("cod_area", code);
    if (city !== "AMBA" && city !== "Bs. As." && setShowModalInterior) {
      setShowModalInterior(true);
    };
  };

  const handleEventLeft = () => {
    window.location.reload();
  };

  const handleEventRight = () => {
    setShowModalInterior(false);
  };

  return (
    <div className="card-contact-data" style={{ width: "314px" }}>
      <div className="card-contact-header">
        <p className="contact-header-title">
          {verified_phone ? "¡Estos son tus datos!" : "¡Completá tus datos!"}
        </p>
      </div>
      {showSpinner ? (
        <div style={{ marginTop: "50px" }}>
          <SpinnerComponent />
        </div>
      ) : (
        <>
          <div style={{ padding: "12px", height: "318px", width: "290px" }}>
            <form
              className="d-flex flex-column justify-content-between"
              // onSubmit={handleSubmitWithUser}
              onSubmit={handleSubmit(email?.includes("@auta.com.ar") ? sendAutaSale : sendCotiza)}
              style={{
                height: "100%",
              }}
            >
              {email?.includes("@auta.com.ar") ?
                <div>
                  <div>
                    <label className="input-labels">Nombre</label>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      {editName ?
                        <>
                          <input
                            className="base-input"
                            {...register("name", {
                              required: true,
                            })}
                            placeholder="Ingresá el nombre"
                            onChange={(e) => handleChange(e, "name")}
                            style={{ height: "30px" }}
                          />
                          <img
                            src={IconClose}
                            alt="edit-icon"
                            style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                            className=""
                            onClick={() => setEditName(false)}
                          />
                        </>
                        :
                        <>
                          <p style={{ height: "30px", alignContent: "center" }}>{getValues("name") ? getValues("name") : name}</p>
                          <img
                            src={IconEdit}
                            alt="edit-icon"
                            style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                            className=""
                            onClick={() => setEditName(true)}
                          />
                        </>
                      }
                    </div>
                  </div>
                  <div>
                    <label className="input-labels">Email</label>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      {editEmail ?
                        <>
                          <input
                            className="base-input"
                            {...register("email", {
                              required: false,
                            })}
                            placeholder="Ingresá el mail"
                            onChange={(e) => handleChange(e, "email")}
                            style={{ height: "30px" }}
                          />
                          <img
                            src={IconClose}
                            alt="edit-icon"
                            style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                            onClick={() => setEditEmail(false)}
                          />
                        </>
                        :
                        <>
                          <p style={{ height: "30px", alignContent: "center" }}>{getValues("email") ? getValues("email") : email}</p>
                          <img
                            src={IconEdit}
                            alt="edit-icon"
                            style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                            onClick={() => setEditEmail(true)}
                          />
                        </>
                      }
                    </div>
                  </div>
                  <div>
                    <label className="input-labels">Teléfono celular</label>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      {editPhone || !phone ?
                        <>
                          <label className="phone-number" style={{ height: "30px", alignContent: "center", fontSize: "16px" }}>+549</label>
                          <input
                            type="text"
                            className="base-input"
                            placeholder="Ej: 11"
                            maxLength={4}
                            style={{ width: "65px", height: "30px" }}
                            {...register("cod_area", {
                              required: true,
                            })}
                            onChange={(e) => handleChange(e, "cod_area")}
                          />
                          <input
                            type="text"
                            className="base-input ms-1"
                            placeholder="Sin 15"
                            {...register("telefono", {
                              required: true,
                            })}
                            onChange={(e) => handleChange(e, "telefono")}
                          />
                          {phone &&
                            <img
                              src={IconClose}
                              alt="edit-icon"
                              style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                              onClick={() => setEditPhone(false)}
                            />
                          }
                        </>
                        :
                        <>
                          <p style={{ height: "30px", alignContent: "center" }}>{getValues("telefono") ? `+549${getValues("cod_area"), getValues("telefono")}` : phone}</p>
                          <img
                            src={IconEdit}
                            alt="edit-icon"
                            style={{ height: "24px", cursor: "pointer", alignSelf: "center" }}
                            onClick={() => setEditPhone(true)}
                          />
                        </>
                      }
                    </div>
                    <p className="text-error">{textError}</p>
                  </div>
                </div>
                :
                <div>
                  <div className="mb-2">
                    <label className="input-labels">Nombre</label>
                    <p>{name}</p>
                  </div>
                  {email &&
                    <div className="mb-2">
                      <label className="input-labels">Email</label>
                      <p>{email}</p>
                    </div>
                  }
                  <div className="mb-2">
                    <label className="input-labels">Teléfono celular</label>
                    {phone ?
                      <p>+{phone}</p>
                      :
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className="phone-number">+549</p>
                        <input
                          type="text"
                          className="base-input"
                          placeholder="Ej: 11"
                          maxLength={4}
                          style={{ width: "65px", marginRight: "10px" }}
                          {...register("cod_area", {
                            required: true,
                          })}
                          /* onChange={(e) => {
                            setcodArea(e.target.value.length);
                          }} */
                          onChange={handleChangeAreaCode}
                          autoComplete="off"
                        />
                        {areaCodesOpen &&
                          <div className="base-input area-codes-container">
                            {areaCodes?.map((code) => {
                              for (let key in code) {
                                return (
                                  <div key={code[key]} className="area-codes-option" onClick={() => handleClickAreaCode(code[key], key)}>
                                    <label className="area-codes-number">{code[key]}</label>
                                    <label className="area-codes-city">{key}</label>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        }
                        <input
                          type="text"
                          className="base-input ms-1"
                          placeholder="Sin 15"
                          {...register("telefono", {
                            required: true,
                            maxLength: Math.abs(codArea - 10),
                            minLength: Math.abs(codArea - 10),
                          })}
                          maxLength={Math.abs(parseInt(codArea?.length) - 10)}
                          minLength={Math.abs(parseInt(codArea?.length) - 10)}
                        />
                      </div>
                    }
                    <p className="text-error">{textError}</p>
                  </div>
                </div>
              }
              <div className="d-flex flex-column justify-content-between align-items-center">
                {errorMessage ?
                  <p className="security-code-text mb-3" style={{ color: colors.red.red }}>
                    {errorMessage}
                  </p>
                  :
                  <>
                    {editPhone &&
                      <p className="security-code-text mb-3">
                        Recibirás un código de seguridad.
                      </p>
                    }
                  </>
                }
                {isLoading ? (
                  <Spinner
                    animation="border"
                    style={{
                      margin: "10px",
                      color: " #7340d8",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <BasePurpleButtonComponent
                  buttonText={isLoading ? "Enviando..." : "Continuar"}
                  buttonDisabled={isLoading ? true : false}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </>
      )}
      {window.location.pathname === "/vende" &&
        <ModalInterior
          setShow={setShowModalInterior}
          show={showModalInterior}
          title={"¿Vivís en el interior del país?"}
          subtitle={
            isPageWide ?
              "Por el momento, solo realizamos inspecciones en la\nCiudad Autónoma de Buenos Aires (CABA)."
              :
              "Por el momento, solo realizamos\ninspecciones en la Ciudad Autónoma\nde Buenos Aires (CABA)."
          }
          subtitleTwo={"Agradecemos tu comprensión"}
          textBtnLeft={"Entiendo :("}
          textBtnRight={"¡Avancemos :)!"}
          handleEventLeft={handleEventLeft}
          handleEventRight={handleEventRight}
        />
      }
    </div>
  );
};
