import React, { useEffect, useRef, useState } from "react";
import "../../style/contactDataForm.css";
import { TitleHomeWithIcon } from "../../../Home/components/TitleHomeWithIcon";
import { ModalAuthenticated } from "../Modals/ModalAuthenticated";
import { ModalVerificationPhone } from "../Modals/ModalVerificationPhone";
import { colors } from "../../../../styles/colors";
import SuccessComponent from "../../SuccessComponent";

export default function ContactDataFormSaleComponent({ dataCar, kms, isGnc, cotiza }) {
  const [currentView, setCurrentView] = useState("step1");
  const [isLoading, setIsLoading] = useState();
  const [isDisabledB, setIsDisabledB] = useState(false);
  const [selectedButton, setSelectedButton] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCodes, setVerificationCodes] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]); // Estado para el código de verificación
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]; // Referencias a los inputs
  const [textError, setTextError] = useState("");
  const [priceQuote, setPriceQuote] = useState();

  useEffect(() => {
    handleButtonClick(selectedButton);
  }, [selectedButton]);

  const handleButtonClick = (buttonNumber) => {
    setSelectedButton(buttonNumber);
  };

  const handleCloseModal = () => {
    window.location.reload();
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    // Verifica si el valor ingresado es un número o una cadena vacía y está en el rango de 0 a 9
    if (/^[0-9]$|^$/.test(value) && index >= 0 && index <= 5) {
      const newVerificationCodes = [...verificationCodes];
      newVerificationCodes[index] = value;
      setVerificationCodes(newVerificationCodes);

      // Mueve el foco al siguiente input solo si se ha ingresado un dígito
      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleVerification = async (e) => {};

  return (
    <>
      {currentView === "step3" ? (
        <TitleHomeWithIcon
          title={"¡Tu cotización fue\n realizada con éxito!"}
          fontSize={"28px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      ) : (
        <TitleHomeWithIcon
          title={"¡Ya casi terminamos!"}
          fontSize={"28px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      )}
      {currentView === "step1" ? (
        <ModalAuthenticated
          textError={textError}
          isLoading={isLoading}
          dataCar={dataCar}
          kms={kms}
          isGnc={isGnc}
          setPhoneNumber={setPhoneNumber}
          setIsLoading={setIsLoading}
          setCurrentView={setCurrentView}
          setPriceQuote={setPriceQuote}
          cotiza={cotiza}
        />
      ) : currentView === "step2" ? (
        <ModalVerificationPhone
          handleVerification={handleVerification}
          countryCode={countryCode}
          phoneNumber={phoneNumber}
          inputRefs={inputRefs}
          verificationCodes={verificationCodes}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          textError={"Código inválido"}
          isDisabledB={isDisabledB}
          dataCar={dataCar}
          setCurrentView={setCurrentView}
          handleChange={handleChange}
          setPriceQuote={setPriceQuote}
          isGnc={isGnc}
          kms={kms}
        />
      ) : currentView === "step3" ? (
        <SuccessComponent
          handleClick={handleCloseModal}
          priceQuote={priceQuote}
          brand={dataCar?.brand.name}
          model={dataCar?.model.name}
          version={dataCar?.version.name}
          year={dataCar?.year.name}
          kms={kms}
        />
      ) : null}
    </>
  );
}
