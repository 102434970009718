import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPhoneCode,
  checkPhoneCodeByPhone,
  sendPhoneCodeByPhone,
} from "../../../../actions/phoneVerification";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { reCheckUserExistence } from "../../../../actions/user";
import { colors } from "../../../../styles/colors";

export const ModalVerificationPhone = ({
  countryCode,
  phoneNumber,
  dataCar,
  textError,
  isDisabledB,
  setCurrentView,
  isGnc,
  kms,
  setPriceQuote,
  setIsModalOpen,
  successRedirect,
  isLoading,
  setIsLoading,
  handleButtonClick,
  isSale,
  width
}) => {
  const { token } = useSelector((state) => state.authReducer);
  const { phone } = useSelector((state) => state.userReducer);
  const { register, handleSubmit, setValue } = useForm();
  const [isError, setIsError] = useState(false);
  const [timer, setTimer] = useState(300); // Timer starts at 300 seconds (5 minutes)
  const [canResend, setCanResend] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    reCheckUserExistence(phone, dispatch);
  }, [phone]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown); // Cleanup the timeout
    } else {
      setCanResend(true);
    }
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleChange = (e, inputName, index) => {
    setIsError(false);
    const value = e.target.value.slice(0, 1); // Limitar el valor a un solo dígito

    // Verifica si el valor ingresado es un número o una cadena vacía y está en el rango de 0 a 9
    if (/^[0-9]$|^$/.test(value) && index >= 0 && index <= 5) {
      setValue(inputName, value);

      // Mueve el foco al siguiente input solo si se ha ingresado un dígito
      if (index < inputRefs.current.length - 1 && value) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      setValue(inputName, "");
    }
  };

  const handleVerification = async (data) => {
    setIsLoading(true);
    const code = Object.values(data).join("");
    if (token) {
      checkPhoneCode(
        token,
        code,
        setIsError,
        dispatch,
        setIsLoading,
        setCurrentView,
        setIsModalOpen
      );
    } else {
      checkPhoneCodeByPhone(
        phone,
        code,
        setIsError,
        dispatch,
        setIsLoading,
        setCurrentView,
        setIsModalOpen,
        successRedirect,
        navigate
      );
    }
  };

  // Maneja cuando el usuario presiona una tecla (para detectar "Backspace")
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  // Maneja el evento de pegar un código
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === 6 && /^\d+$/.test(pasteData)) {
      // Verifica que los datos pegados son 6 dígitos
      pasteData.split("").forEach((digit, index) => {
        setValue(`code${index + 1}`, digit);
        inputRefs.current[index].value = digit;
      });

      // Enfoca el último input después de pegar los dígitos
      inputRefs.current[5].focus();
    }
    e.preventDefault();
  };

  return (
    <div className={isSale ? 'mobile-dropdown-container' : `card-contact-data`} style={width ? { width } : {}}>
      <div className="card-contact-header">
        <p className="contact-header-title">Verificación telefónica.</p>
      </div>
      <div
        style={{
          padding: "20px",
          height: "300px",
          width: "100%",
        }}
      >
        <form
          onSubmit={handleSubmit(handleVerification)}
          className="d-flex flex-column" style={{ height: "260px" }}
        >
          <div className="d-flex flex-column" style={{ height: "100%" }}>
            <p className="phone-number-two">
              Ingresá el código que te enviamos por Whatsapp al{" "}
              <span className="phone-number-title">{phoneNumber ?? phone}</span>
            </p>
            <div
              onPaste={handlePaste}
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around"
              }}
            >
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  onInput={(e) => {
                    if (e.target.value.length > 1) {
                      e.target.value = e.target.value.slice(0, 1);
                    }
                  }}
                  autoComplete="off"
                  maxLength="1"
                  className="code-input"
                  {...register(`code${index + 1}`)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onChange={(e) => handleChange(e, `code${index + 1}`, index)}
                />
              ))}
            </div>
            {isLoading ?
              <></>
              :
              <>
                <p className="text-countdown mt-4">
                  Reenviar código de verificación en:
                  <span
                    className="ms-1"
                    style={{ color: colors.purple.purple, fontWeight: "700" }}
                  >
                    {formatTime(timer)}
                  </span>
                </p>
                {isLoadingResend ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner
                      animation="border"
                      style={{
                        margin: "10px",
                        color: " #7340d8",
                      }}
                      className="mt-4"
                    />
                  </div>
                ) : (
                  <p
                    className="mt-4 send-code-again"
                    style={{
                      color: canResend
                        ? colors.purple.purple
                        : colors.purple.lightPurple,
                      cursor: canResend ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      if (canResend) {
                        setIsLoadingResend(true);
                        sendPhoneCodeByPhone(
                          phone,
                          setIsLoadingResend,
                          setShowLogin
                        );
                        setTimer(300);
                        setCanResend(false);
                      }
                    }}
                  >
                    Reenviar código
                  </p>
                )}
              </>
            }
            {isError ? (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {textError}
              </p>
            ) : (
              <></>
            )}
          </div>
          {isLoading ? (
            <Spinner
              animation="border"
              style={{
                margin: "40px auto 30px",
                color: " #7340d8",
                width: "32px",
                height: "58px"
              }}
            />
          ) : (
            ""
          )}
          <div
            className="verification-phone-button"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textAlignLast: "center"
            }}
          >
            <BasePurpleButtonComponent
              buttonDisabled={isLoading}
              buttonText={"Continuar"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
