import React, { useState } from "react";
import "../styles/components/baseDropDown.css";

export default function BaseDropDownComponent({
  children,
  type,
  textButton,
  isDisabled,
  setSelectedOptions,
  selectedOptions,
  dataSelectedOptions,
  onItemClick,
  setShowDropdown,
  showDropdown,
  setShowDropdownBrand,
  setShowDropdownModel,
  setShowDropdownVersion,
  setShowDropdownKms,
  setShowDropdownYear,
  setShowDropdownSale
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const styleTextTitleDropdown = {
    color: "white",
    fontSize: "14px",
    fontWeight: "400",
  };

  const toggleMenu = () => {
    setShowDropdown(!isOpen);
    if (type === "year") {
      setShowDropdownBrand(false);
      setShowDropdownModel(false);
      setShowDropdownVersion(false);
      setShowDropdownKms(false);
      setShowDropdownSale(false);
    } else if (type === "brand") {
      setShowDropdownYear(false);
      setShowDropdownModel(false);
      setShowDropdownVersion(false);
      setShowDropdownKms(false);
      setShowDropdownSale(false);
    } else if (type === "model") {
      setShowDropdownBrand(false);
      setShowDropdownYear(false);
      setShowDropdownVersion(false);
      setShowDropdownKms(false);
      setShowDropdownSale(false);
    } else if (type === "version") {
      setShowDropdownModel(false);
      setShowDropdownBrand(false);
      setShowDropdownYear(false);
      setShowDropdownKms(false);
      setShowDropdownSale(false);
    } else if (type === "kms") {
      setShowDropdownVersion(false);
      setShowDropdownModel(false);
      setShowDropdownBrand(false);
      setShowDropdownYear(false);
      setShowDropdownSale(false);
    } else if (type === "sale") {
      setShowDropdownVersion(false);
      setShowDropdownModel(false);
      setShowDropdownBrand(false);
      setShowDropdownYear(false);
    }
  };

  const handleItemClick = (item, id) => {
    setSelectedItem(item);
    setIsOpen(false); // Cierra el menú cuando se selecciona un elemento
    onItemClick(item, id);
    if (type === "year") {
      setSelectedOptions({
        year: {
          name: item,
          id: id,
        },
        brand: null,
        model: null,
        version: null,
        kms: null,
        sale: null
      });
      setShowDropdownBrand(true);
      setShowDropdownModel(false);
      setShowDropdownVersion(false);
      setShowDropdownKms(false);
      setShowDropdownSale(false);
    } else if (type === "brand") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions.year.name,
          id: dataSelectedOptions.year.id,
        },
        brand: {
          name: item,
          id: id,
        },
        model: null,
        version: null,
        kms: null,
        sale: null
      });
      setShowDropdownModel(true);
      setShowDropdownVersion(false);
      setShowDropdownKms(false);
      setShowDropdownSale(false);
    } else if (type === "model") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions?.year?.name,
          id: dataSelectedOptions?.year?.id,
        },
        brand: {
          name: dataSelectedOptions?.brand?.name,
          id: dataSelectedOptions?.brand?.id,
        },
        model: {
          name: item,
          id: id,
        },
        version: null,
        kms: null,
        sale: null
      });
      setShowDropdownVersion(true);
      setShowDropdownKms(false);
      setShowDropdownSale(false);
    } else if (type === "version") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions?.year?.name,
          id: dataSelectedOptions?.year?.id,
        },
        brand: {
          name: dataSelectedOptions?.brand?.name,
          id: dataSelectedOptions?.brand?.id,
        },
        model: {
          name: dataSelectedOptions?.model?.name,
          id: dataSelectedOptions?.model?.id,
        },
        version: {
          name: item,
          id: id,
        },
        kms: null,
        sale: null
      });
      setShowDropdownKms(true);
      setShowDropdownSale(false);
    } else if (type === "kms") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions?.year?.name,
          id: dataSelectedOptions?.year?.id,
        },
        brand: {
          name: dataSelectedOptions?.brand?.name,
          id: dataSelectedOptions?.brand?.id,
        },
        model: {
          name: dataSelectedOptions?.model?.name,
          id: dataSelectedOptions?.model?.id,
        },
        version: {
          name: dataSelectedOptions?.version?.name,
          id: dataSelectedOptions?.version?.id,
        },
        kms: {
          name: item,
          id: id,
        },
        sale: null
      });
      setShowDropdownKms(false);
    }
  };

  return (
    <div className="custom-dropdown-container container-text-animated me-2">
      <button
        onClick={toggleMenu}
        className={`custom-dropdown-button  ${selectedItem ? "selected-value" : ""
          }`}
        disabled={isDisabled}
      >
        {selectedOptions?.length > 22 ? (
          <div id="animated-text-strip" className="animated-text-container">
            <>
              <div
                style={styleTextTitleDropdown}
                className="text-animated none-padding"
              >
                &nbsp;{selectedOptions}&nbsp;&nbsp; &nbsp; &nbsp;
              </div>
              <div
                style={styleTextTitleDropdown}
                className="text-animated none-padding"
              >
                &nbsp;{selectedOptions}&nbsp;&nbsp; &nbsp; &nbsp;
              </div>
              <div
                style={styleTextTitleDropdown}
                className="text-animated none-padding"
              >
                &nbsp; {selectedOptions}&nbsp;&nbsp; &nbsp; &nbsp;
              </div>
            </>
          </div>
        ) : (
          <p>{selectedOptions || textButton}</p>
        )}
      </button>
      {showDropdown && (
        <div className={`menu ${showDropdown ? "open" : ""}`}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onItemClick: handleItemClick, // Pasa la función handleItemClick como prop
              selectedItem,
            })
          )}
        </div>
      )}
    </div>
  );
}
